"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalStyle = void 0;
// https://www.styled-components.com/
const styled_components_1 = require("styled-components");
exports.GlobalStyle = (0, styled_components_1.createGlobalStyle) `
body {
    font-family: "Microsoft YaHei", "黑体", "宋体", sans-serif;
    margin: 0;
    padding: 0;
    font-style: normal;
  }
`;
