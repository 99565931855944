"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnchorButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const styles_1 = require("./styles");
/** A collection of curated prop keys used across ButtonProps which are not valid HTMLElement props. */
const INVALID_PROPS = ['icon', 'text', 'children'];
const removeNonHTMLProps = (props, invalidProps = INVALID_PROPS) => invalidProps.reduce((prev, curr) => {
    if (prev[curr]) {
        delete prev[curr];
    }
    return prev;
}, { ...props });
const ButtonChildren = ({ icon, text, children }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [icon && (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: icon }, "icon"), (text || children) && ((0, jsx_runtime_1.jsxs)("span", { children: [text, children] }, "text"))] }));
const Button = (props) => ((0, jsx_runtime_1.jsx)(styles_1.ButtonWrapper, { ...removeNonHTMLProps(props), children: (0, jsx_runtime_1.jsx)(ButtonChildren, { ...props }) }));
const AnchorButton = (props) => ((0, jsx_runtime_1.jsx)(styles_1.AnchorButtonWrapper, { role: "button", ...removeNonHTMLProps(props), children: (0, jsx_runtime_1.jsx)(ButtonChildren, { ...props }) }));
exports.AnchorButton = AnchorButton;
exports.default = Button;
